import React from "react";

const DownArrow = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={11}
                height={11}
                fill="none"
                {...props}
            >
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.5}
                    d="M1.6 9.358h7.8V1.463M9.307 9.28 1.6 1.578"
                />
            </svg>
        </>
    );
};

export default DownArrow;

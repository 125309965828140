import React from "react";

const MarkIcon = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={11}
                fill="none"
                {...props}
            >
                <path
                    stroke={!props?.stroke ? "#AAAFC5" : props?.stroke}
                    strokeWidth={2}
                    d="M1 4.79 5.138 9 13 1"
                />
            </svg>
        </>
    );
};

export default MarkIcon;

import React from "react";

const GradientBG = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="733"
                height="590"
                viewBox="0 0 733 590"
                fill="none"
            >
                <g filter="url(#filter0_f_244_1134)">
                    <path
                        d="M256.992 -190.46C256.992 -200.326 263.917 -195.393 272.461 -190.46H763.533C772.077 -185.527 779.003 -200.326 779.003 -190.46L779.029 928.03C779.029 937.895 772.103 932.963 763.56 928.03H272.488C263.944 923.097 257.018 937.895 257.018 928.03L256.992 -190.46Z"
                        fill="black"
                    />
                    <path
                        d="M256.992 -190.46C256.992 -200.326 263.917 -195.393 272.461 -190.46H763.533C772.077 -185.527 779.003 -200.326 779.003 -190.46L779.029 928.03C779.029 937.895 772.103 932.963 763.56 928.03H272.488C263.944 923.097 257.018 937.895 257.018 928.03L256.992 -190.46Z"
                        fill="url(#paint0_linear_244_1134)"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_f_244_1134"
                        x="0.121887"
                        y="-453.027"
                        width="1035.78"
                        height="1643.62"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="128.435"
                            result="effect1_foregroundBlur_244_1134"
                        />
                    </filter>
                    <linearGradient
                        id="paint0_linear_244_1134"
                        x1="490.067"
                        y1="560.943"
                        x2="817.793"
                        y2="185.63"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#CF47FF" />
                        <stop offset="0.52454" stopColor="#4412AE" />
                        <stop offset="0.927491" stopColor="#1F0A68" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
};

export default GradientBG;

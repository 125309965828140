import Image from "next/image";
import Link from "next/link";
import useLazyLoad from "../../../../hooks/useLazyLoad";
import CustomImage from "../../common/CustomImage";
import AnimatedCapsule from "../../common/ui/AnimatedCapsule";
import GradientBG from "../../icon/GradientBG";
import GridIconNew from "../../icon/GridIconNew";
import TitleHome from "./TitleHome";

const Benefits = () => {
    const [isVisible, videoRef] = useLazyLoad();
    const data = {
        button: {
            btn_title: "We Build Trust",
            btn_link: "/about-us"
        },
        title: {
            text: "Trade with the Most Reliable Prop Firm",
            customStyle: "xl:w-9/12 xl:mx-auto"
        },
        description: {
            text: "Accurate | Fast | Stable | Trustworthy",
            customStyle: ""
        },
        customStyle: "w-full md:w-1/2 lg:w-8/12 mx-auto"
    };
    const gradients = [
        "linear-gradient(180deg, #8B55FF 0%, #4D19E0 100%)",
        "linear-gradient(180deg, #56E8FF 0%, #0167E0 100%)",
        "linear-gradient(180deg, #FF69BA 0%, #D40140 100%)"
    ];
    const renderGuaranteedPayout = () => {
        return (
            <div
                className="rounded-[20px] p-[30px] transition-all duration-300 ease-in-out hover:scale-[102%]"
                style={{
                    background:
                        "linear-gradient(180deg, #8055FF 0%, #4D19E0 100%)"
                }}
            >
                <CustomImage
                    src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/Guaranteed%20Payout-update.png"
                    height={180}
                    width={180}
                    layout="fixed"
                    alt="usp1"
                    className=""
                />

                <p className="mt-5 text-[28px] font-[600] leading-[40px] text-white xl:font-[500]">
                    Guaranteed <br /> Payouts
                </p>
                <p className="pt-5 font-Inter text-base font-normal leading-[26px] text-[rgba(255,255,255,0.75)]">
                    Get paid in 24 hours or <br /> we pay $1,000 extra.
                </p>
            </div>
        );
    };
    const renderBestTrading = () => {
        return (
            <div
                className="flex items-center justify-between rounded-[20px] px-[30px]   py-5 transition-all duration-300 ease-in-out hover:scale-[102%]"
                style={{
                    background:
                        "linear-gradient(180deg, #3F7715 -12.67%, #094F29 100%)"
                }}
            >
                <div className="w-7/12">
                    <p className="pt-[10px] text-[24px] font-[500] text-white lg:text-[24px]">
                        Best Trading Conditions
                    </p>
                </div>
                <div className="flex w-5/12 justify-end">
                    <CustomImage
                        src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/image116.png"
                        height={109}
                        width={102}
                        layout="fixed"
                        alt="usp2"
                        className=""
                    />
                </div>
            </div>
        );
    };
    const states = [
        {
            number: "$95M+",
            text: "Total Rewards"
        },
        {
            number: "97k+",
            text: "Traders"
        },
        {
            number: "5hrs",
            text: "Avg. Reward Time"
        }
    ];
    const socialIConList = [
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/discord.svg",
            link: "https://discord.com/invite/fundednext-945622549373526056"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/facebook.svg",
            link: "https://www.facebook.com/groups/fundednext"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/instagram-logo-new.svg",
            link: "https://www.instagram.com/fundednext"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/twitter.svg",
            link: "https://x.com/FundedNext"
        },
        {
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/youtube.svg",
            link: "https://www.youtube.com/@FundedNext"
        }
    ];
    const renderBestPlatform = () => {
        return (
            <div className="relative h-full min-h-[650px] w-full overflow-hidden rounded-[20px] bg-[#26116D] px-[30px] py-11 transition-all duration-300 ease-in-out hover:scale-[102%] md:min-h-[470px] lg:min-h-0 lg:px-[50px]">
                <span className="absolute left-[-15%] top-[70px] lg:top-[40px]">
                    <GridIconNew />
                </span>
                <p className=" relative z-[1] font-Inter  text-sm font-extrabold uppercase tracking-[2px] text-[#FFD11B] lg:text-base">
                    〽️ Your Choice!
                </p>
                <p className="relative  z-[1] py-2 text-[28px] font-[600] leading-[44px] text-white lg:leading-[57px] xl:font-[500]">
                    Best Trading Platforms
                </p>
                <p className="relative z-[1] text-sm font-medium leading-6 text-white xl:text-base xl:font-[500] xl:leading-[34px]">
                    Trade on MT4, MT5 & cTrader platforms
                </p>
                <p className="relative z-[99] pt-[14px] font-Inter text-[12px] font-[400] leading-[21px] text-[rgba(255,255,255,0.75)] opacity-75 md:w-[70%] lg:pt-[71px] lg:text-base lg:leading-7">
                    FundedNext traders can choose to trade between MetaTrader 4,
                    MetaTrader 5, or cTrader platforms. Our MQ licenses and
                    advanced in-house technology ensure enhanced security and
                    efficiency.
                </p>
                <div className="absolute bottom-[-75px] right-[-12px] z-[11]  w-[450px] lg:bottom-0 lg:right-[-2px]">
                    <Image
                        src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/trading-platform.png"
                        alt="usp3"
                        width={450}
                        height={450}
                    />
                </div>

                <span className="  absolute bottom-[78px] right-[-271px] md:bottom-[-2px] lg:bottom-[-5px] lg:right-[-227px] xl:bottom-[0px] ">
                    <GradientBG />
                </span>
            </div>
        );
    };
    const renderFundingTrader = () => {
        return (
            <div className="relative flex h-full flex-col overflow-hidden rounded-[20px] px-[20px] pb-[55px] transition-all duration-300 ease-in-out hover:scale-[102%] md:pt-[95px] lg:p-[30px]">
                <div className="absolute bottom-0 left-0 right-0 top-0 z-[11] h-full w-full bg-[#ffffff] opacity-10"></div>
                <div className="absolute left-0 top-0  z-[1] h-full w-full  opacity-60">
                    {isVisible ? (
                        <video autoPlay muted loop playsInline ref={videoRef}>
                            <source src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/Globe%20Animation.mp4" />
                        </video>
                    ) : (
                        <div ref={videoRef}></div>
                    )}
                </div>
                <p className="relative z-[99] mt-[100px] text-[24px] font-[600] text-white xl:font-[500]">
                    Empowering Traders in <br /> 195+ Countries
                </p>

                <div className="relative z-[99] flex gap-x-4 pb-[6px] pt-[4px] lg:gap-x-10">
                    {states.map((state, index) => {
                        return (
                            <div
                                key={index}
                                className=" relative mt-5 pl-2 lg:pl-4"
                            >
                                <div
                                    className="absolute left-0 top-[4px] h-[92%] w-[4px] rounded lg:top-0 lg:h-[50px]"
                                    style={{
                                        background:
                                            gradients[index % gradients.length]
                                    }}
                                />
                                <p className="pb-1 text-lg font-[500] text-white lg:text-[24px] lg:font-[400]">
                                    {state.number}
                                </p>
                                <p className="font-Inter text-xs font-[400] text-[rgba(255,255,255,0.75)]  lg:text-base ">
                                    {state.text}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const renderProSupport = () => {
        return (
            <div className="h-full w-full lg:w-1/2 lg:pr-2">
                <div
                    className="h-full w-full rounded-[20px] p-[30px] transition-all duration-300 ease-in-out hover:scale-[102%]"
                    style={{
                        background:
                            "linear-gradient(180deg, #00A3FF 0%, #0062D5 100%)"
                    }}
                >
                    <CustomImage
                        src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/community.png"
                        height={82}
                        width={82}
                        layout="fixed"
                        alt="usp4"
                        className=""
                    />
                    <p className="pt-[20px] text-[24px] font-[600] leading-[36px] text-white xl:font-[500]">
                        FundedNext Community
                    </p>
                    <p className="pt-5 font-Inter text-base  text-[rgba(255,255,255,0.75)]">
                        Serving over 700,000+ members
                    </p>
                    <div className="flex items-center gap-x-[11px] pt-[12px] lg:pt-[35px] xl:pt-[12px]">
                        {socialIConList.map((singleItem, index) => {
                            return (
                                <Link
                                    key={index}
                                    href={singleItem.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="rounded-[12px] transition-all duration-300 ease-in-out hover:bg-black hover:bg-opacity-10"
                                >
                                    <Image
                                        src={singleItem.icon}
                                        alt=""
                                        height={44}
                                        width={44}
                                        layout="fixed"
                                    />
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    const renderFundedNextCommunity = () => {
        return (
            <div className="w-full lg:w-1/2 lg:pl-2">
                <div
                    className="flex h-full w-full flex-col rounded-[20px] px-[30px] py-[40px] pr-2 transition-all duration-300 ease-in-out hover:scale-[102%]"
                    style={{
                        background:
                            "linear-gradient(180deg, #8B55FF 0%, #4E19E0 100%);"
                    }}
                >
                    <CustomImage
                        src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/customer-support.png"
                        height={71}
                        width={71}
                        layout="fixed"
                        alt="usp4"
                        className=""
                    />
                    <p className="pt-[25px] text-[24px] font-[600] leading-[36px]  text-white xl:font-[500]">
                        24/7 <br /> Pro Support
                    </p>
                    <p className="pt-3.5 w-11/12 font-Inter text-base font-[400] text-[rgba(255,255,255,0.75)]">
                        Experience our Pro Support with a 50-second average
                        response time.
                    </p>
                </div>
            </div>
        );
    };
    return (
        <>
            <div className=" container-v2 lg:px-0">
                <div className="mb-4 flex justify-center">
                    <AnimatedCapsule
                        text="Why FundedNext"
                        bgColor={"#141414"}
                    />
                </div>
                <TitleHome content={data} />
                <div className="mt-10 flex w-full flex-wrap items-stretch gap-y-4 lg:mt-[100px]">
                    <div className="flex w-full flex-col gap-y-4 lg:w-4/12">
                        {renderGuaranteedPayout()}
                        {renderBestTrading()}
                    </div>
                    <div className="w-full lg:w-8/12 lg:pl-4">
                        {renderBestPlatform()}
                    </div>
                    <div className=" w-full lg:w-1/2 lg:pr-2">
                        {renderFundingTrader()}
                    </div>
                    <div className="flex h-full w-full flex-wrap gap-y-5 lg:w-1/2 lg:pl-2">
                        {renderProSupport()}
                        {renderFundedNextCommunity()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Benefits;

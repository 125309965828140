import React from "react";

const GreenUpperArrow = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={18}
                fill="none"
                {...props}
            >
                <path
                    fill="#43B077"
                    fillRule="evenodd"
                    d="M5.44 18h3.758V9.63h4.621L7.32 0 .82 9.63H5.44V18Z"
                    clipRule="evenodd"
                />
            </svg>
        </>
    );
};

export default GreenUpperArrow;

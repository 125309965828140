import React from "react";

export const Graph = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={80}
                height={36}
                fill="none"
                {...props}
            >
                <path
                    stroke="#C6C8EF"
                    strokeLinecap="round"
                    strokeWidth={2}
                    d="m1.18 34.314 4.585-7.201c3.382-5.315 11.13-5.345 14.56-.06 3.317 5.111 10.735 5.29 14.291.34l3.582-4.988c4.027-5.603 11.764-7.022 17.52-3.216l6.793 4.487c4.984 3.297 10.469 2.71 11.612-3.157.569-2.935.913-6.666.76-11.22"
                    opacity={0.4}
                />
                <path
                    stroke="#43B077"
                    strokeLinecap="round"
                    strokeWidth={2}
                    d="m1.18 30.068 3.191-5.01c3.764-5.913 12.559-5.39 15.6.923 3.191 6.627 12.576 6.78 15.983.255l3.127-5.99c3.386-6.484 11.637-8.612 17.737-4.58l6.433 4.257c4.512 2.982 9.52 2.502 10.689-2.779.675-3.055 1.109-7.074.943-12.091"
                />
                <path
                    fill="#fff"
                    stroke="#43B077"
                    strokeLinecap="round"
                    strokeWidth={2}
                    d="M75.451 8.422a3.368 3.368 0 1 0 0-6.736 3.368 3.368 0 0 0 0 6.736Z"
                />
            </svg>
        </>
    );
};

export const LargeScreenGraph = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="143"
            height="62"
            viewBox="0 0 143 62"
            fill="none"
            {...props}
        >
            <path
                opacity="0.4"
                d="M2.23145 60L10.4112 47.1504C16.4456 37.6667 30.2731 37.6137 36.3909 47.0443C42.3116 56.1641 55.5479 56.4824 61.8931 47.6507L68.2839 38.7507C75.4705 28.7516 89.2751 26.2196 99.5472 33.0121L111.669 41.0174C120.562 46.9002 130.349 45.8541 132.388 35.3849C133.404 30.1465 134.017 23.4905 133.744 15.3638"
                stroke="#C6C8EF"
                strokeWidth="3.56877"
                strokeLinecap="round"
            />
            <path
                d="M2.23145 52.4233L7.92487 43.4854C14.6415 32.9328 30.3337 33.8653 35.7616 45.1305C41.4549 56.9567 58.2011 57.2295 64.281 45.5853L69.8604 34.8963C75.9023 23.3279 90.6244 19.5299 101.511 26.7241L112.988 34.3202C121.039 39.6419 129.977 38.7853 132.062 29.3622C133.267 23.9116 134.04 16.7401 133.744 7.78711"
                stroke="#43B077"
                strokeWidth="3.56877"
                strokeLinecap="round"
            />
            <path
                d="M134.758 13.7986C138.077 13.7986 140.768 11.1079 140.768 7.78872C140.768 4.46954 138.077 1.77881 134.758 1.77881C131.439 1.77881 128.748 4.46954 128.748 7.78872C128.748 11.1079 131.439 13.7986 134.758 13.7986Z"
                fill="white"
                stroke="#43B077"
                strokeWidth="3.56877"
                strokeLinecap="round"
            />
        </svg>
    );
};
